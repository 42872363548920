import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue';
// import Detail from '../views/Detail.vue';
import Wallet from '../views/Wallet.vue';
import WalletList from '../views/WalletList.vue';
import WalletMake from '../views/WalletMake.vue';
import WalletViewItem from '../views/WalletViewItem.vue';
import Join from '../views/Join.vue';
import MyHome from '../views/MyHome.vue';
import MyInfo from '../views/MyInfo.vue';
import Pin from '../views/Pin.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    name: 'detail',
    path: '/event/:eventCode',
    component: () => import('@/views/Detail')
  },
  // {
  //   path: '/detail',
  //   components: {
  //     default: Detail,
  //     modal: Wallet
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       components: {
  //         default: Detail,
  //         modal: Wallet
  //       }
  //     },
  //     {
  //         path: 'wallet',
  //         components: {
  //           default: Detail,
  //           modal: Wallet
  //         }
  //     },
  //     {
  //       path: 'wallet-list',
  //       components: {
  //         default: Detail,
  //         modal: WalletList
  //       }
  //     },
  //  ],
  // },
  {
    path: '/wallet',
    component: Wallet
  },
  {
    path: '/wallet-list',
    component: WalletList
  },
  {
    path: '/wallet-make',
    component: WalletMake
  },
  {
    path: '/wallet-viewitem',
    component: WalletViewItem
  },
  {
    path: '/join',
    component: Join
  },
  {
    path: '/myhome',
    component: MyHome
  },
  {
    path: '/myinfo',
    component: MyInfo
  },
  {
    path: '/pin',
    component: Pin
  },
  // {
  //   path: '/test',
  //   components: {
  //     default: Home,
  //     modal: Wallet
  //   }
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
});

export default router


