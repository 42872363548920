<template>
	<div id="home">
    <!-- swiper start   -->
    <swiper  id="banner" :options="swiperOptions" class="swiper swiper_01">
<!--        <ul class="swiper-wrapper">-->
          <swiper-slide class="banner_01" v-for="(item,index) in mission_list" :key="index" :style="'background-image:url(' + imageSrc + item.mission_image + ')'">

<!--          <li class="swiper-slide banner_01">-->
            <div>
              <h2>이벤트에 참여하고 <br /><em>누구나 보상받으세요</em></h2>
              <p>회원가입도 앱 설치도 필요없이 <br />미션에 참여하는 누구나 보상을 드립니다!</p>
              <ul>
                <li @click="goDetail(item)"><a href="javascript:;">자세히보기</a></li>
                <li><a @click="goStart(item)">시작하기</a></li>
              </ul>
            </div>
<!--          </li>-->
          </swiper-slide>
<!--          <swiper-slide class="banner_01">-->
<!--            &lt;!&ndash;          <li class="swiper-slide banner_01">&ndash;&gt;-->
<!--            <div>-->
<!--              <h2>1111 <br /><em>누구나 보상받으세요</em></h2>-->
<!--              <p>회원가입도 앱 설치도 필요없이 <br />미션에 참여하는 누구나 보상을 드립니다!</p>-->
<!--              <ul>-->
<!--                <li><a href="javascript:">자세히보기</a></li>-->
<!--                <li><a href="javascript:">시작하기</a></li>-->
<!--              </ul>-->
<!--            </div>-->
<!--            &lt;!&ndash;          </li>&ndash;&gt;-->
<!--          </swiper-slide>-->
<!--        </ul>-->
        <div class="swiper-button-prev" slot="button-prev"><img src="../assets/common/ico-24-prev.svg" alt="이전" /></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/common/ico-24-next.svg" alt="다음" /></div>
<!--      </div>-->
    </swiper>
<!--		<div id="banner" class="swiper swiper_01">-->
<!--			<ul class="swiper-wrapper">-->
<!--				<li class="swiper-slide banner_01">-->
<!--					<div>-->
<!--						<h2>이벤트에 참여하고 <br /><em>누구나 보상받으세요</em></h2>-->
<!--						<p>회원가입도 앱 설치도 필요없이 <br />미션에 참여하는 누구나 보상을 드립니다!</p>-->
<!--						<ul>-->
<!--							<li><a href="javascript:">자세히보기</a></li>-->
<!--							<li><a href="javascript:">시작하기</a></li>-->
<!--						</ul>-->
<!--					</div>-->
<!--				</li>-->
<!--			</ul>-->
<!--			<div class="swiper-button-prev"><img src="../assets/common/ico-24-prev.svg" alt="이전" /></div>-->
<!--			<div class="swiper-button-next"><img src="../assets/common/ico-24-next.svg" alt="다음" /></div>-->
<!--		</div>-->
    <!-- swiper end   -->

		<div class="inner">
			<div class="top">
				<div class="left">
					<div class="title">
						<h3>UPCOMING</h3><span>#곧나와요!</span>
<!--   더보기 버튼은 있으나 눌렀을때 보여줄 페이지 제작은 안됨.         -->
<!--						<a href="javascript:">더보기</a>-->
					</div>
					<div class="list scroll_area">
						<ol>
							<li v-for="(item, i) in upcoming_list" :key="i">
								<a>
                  <router-link :to="'/event/' + item.event_code">
									<div class="thumb">
										<img :src="imageSrc + item.image_path + item.event_code +'.' + item.image_ext + ''" :alt="item.event_name" />
										<span class="no">NO.{{ i+1 }}</span>
<!--										<span class="live"><img src="../assets/common/ico-18-live.svg" alt="라이브" />LIVE</span>-->
<!--										<span class="mark"><img src="../assets/common/xbox.png" alt="XBOX" /></span>-->
									</div>
                  </router-link>
									<div class="subject">
                    <p @click="goStart(item)">{{ item.event_name }}</p>
										<div>
											<p>{{ item.start_date }}~{{ item.end_date }}</p>
											<a href="javascript:" class="btn_favorite" @click="voteInterest(item)"><img src="../assets/common/ico-24-like.svg" alt="관심" /><span>{{ item.interest_cnt}}</span></a>
											<a href="javascript:" class="btn_info" @click="goDetail(item)"><img src="../assets/common/ico-20-info.svg" alt="정보" /></a>
										</div>
									</div>
									<div class="price">
										<dl>
											<dt><img src="../assets/common/ico-20-reward.svg" alt="총보상액" /><span>총보상액</span></dt>
											<dd>{{ (item.payment / eth_price).toFixed(4) }} ETH</dd>
											<dd class="sub">{{ item.payment | makeComma }}원</dd>
										</dl>
									</div>
                </a>
							</li>

						</ol>
					</div>
				</div>

				<div class="right">
					<div class="title">
						<h3><em class="blue">딱 하루</em> 남았어요!</h3>
					</div>
          <div class="swiper-pagination"></div>
          <swiper class="list swiper swiper_02" :options="swiperOptions2">

<!--            <ul class="swiper-wrapper">-->
              <swiper-slide class="swiper-slide" v-for="(item,i) in onday_list" :key="i">
                <a>
                <router-link :to="'/event/' + item.event_code">
                  <div class="thumb">
                    <img :src="imageSrc + item.image_path + item.event_code +'.' + item.image_ext + ''" :alt="item.event_name" />
                    <span class="no dday">D-{{ item.last_day }}</span>
                    <span class="live"><img src="../assets/common/ico-18-live.svg" alt="라이브" />LIVE</span>
<!--                    <span class="mark"><img src="../assets/common/xbox.png" alt="XBOX" /></span>-->
                  </div>
                </router-link>
                  <div class="subject">
                    <p @click="goStart(item)">{{ item.event_name }}</p>
                    <div>
                      <p>{{ item.start_date }}~{{ item.end_date }}</p>
                      <a href="javascript:" class="btn_favorite" @click="voteInterest(item)"><img src="../assets/common/ico-24-like.svg" alt="관심" /><span>{{ item.interest_cnt }}</span></a>
                      <a href="javascript:" class="btn_info" @click="goDetail(item)"><img src="../assets/common/ico-20-info.svg" alt="정보" /></a>
                    </div>
                  </div>
                  <div class="price">
                    <dl>
                      <dt><img src="@/assets/common/ico-20-reward.svg" alt="총보상액" /><span>1인당 보상액</span></dt>
                      <dd>{{ (item.payment / eth_price).toFixed(4) }} ETH</dd>
                      <dd class="sub">{{ item.payment | makeComma }}원</dd>
                    </dl>
                  </div>
                </a>
              </swiper-slide>
<!--            </ul>-->
          </swiper>
				</div>
			</div>

			<div class="bottom">
				<div class="left">
					<div class="title">
						<h3>TOP REWARD</h3><span>#이렇게나많이?</span>
						<p class="btn_refresh"><span>{{ nowTime }} 기준</span><a href="javascript:" @click="getMain"><img src="../assets/common/ico-16-refresh.svg" alt="새로고침" /></a></p>
					</div>
					<div class="list scroll_area">
						<ol>
							<li v-for="(item, i) in topreward_list" :key="i">
								<router-link :to="'/event/'+ item.event_code">
									<div class="thumb">
										<span v-if="i < 3"><img :src="require('@/assets/common/ico-56-award-' + (i+1) + '.svg')" alt="1위" /></span>
										<img :src="imageSrc + item.image_path + item.event_code +'.' + item.image_ext + ''" alt="썸네일" />
									</div>
									<div class="text">
										<p>{{ item.event_name }}</p>
										<dl>
											<dt><img src="../assets/common/ico-20-reward.svg" alt="금액" /><span>{{ (item.payment / eth_price).toFixed(4) }} ETH</span></dt>
											<dd>{{ item.payment | makeComma }}원</dd>
										</dl>
									</div>
								</router-link>
							</li>
						</ol>
					</div>
				</div>

				<div class="right">
					<div class="title">
						<h3>RANKING</h3><span>#누가얼마를벌까?</span>
					</div>
					<div class="list">
						<ol>
							<li v-for="(item, i) in ranking_list" :key="i">
								<a href="javascript:">
									<div class="info">
										<p class="no">{{ i + 1 }}</p>
<!--										<p class="thumb"><img src="../assets/data/mem-photo-01.jpg" alt="썸네일" /></p>-->
										<dl>
											<dt>{{ item.nickname }}</dt>
											<dd>참여이벤트 수 : {{ item.cnt }}개</dd>
										</dl>
									</div>
									<dl>
										<dt>총 보상받은 금액</dt>
										<dd>{{ item.point | makeComma }}원</dd>
									</dl>
								</a>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
    <EventModal ref="eventModal" :imageSrc="imageSrc" :event="modalEvent"></EventModal>
	</div>
</template>

<script>
// import Swiper from 'https://unpkg.com/swiper@8/swiper-bundle.esm.browser.min.js'
// import Swiper from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// import "swiper/swiper.min.css";
// import 'swiper/swiper.scss'
import {getEthPrice, postData, showToast} from '../api/serverApi';
import moment from "moment";
import EventModal from "@/views/EventModal";
export default {
  name: "homeView",
  components: {
    EventModal,
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      imageSrc: '',
      modalEvent: {},
      mission_list: [],
      upcoming_list: [],
      onday_list: [],
      topreward_list: [],
      ranking_list: [],
      eth_price: 0,
      nowTime: null,
      user: {},
      swiperOptions: {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView: 1,
        spaceBetween: 1,
        freeMode: true,
        infiniteMode: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        //   renderBullet(index, className) {
        //     return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
        //   }
        // },
        navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }
      },
      swiperOptions2: {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView: 1,
        spaceBetween: 1,
        freeMode: true,
        infiniteMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // renderBullet(index, className) {
          //   return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
          // }
        },
        on: {
          init: function () {
            document.querySelector(".top .right").prepend(document.querySelector(".swiper-pagination"));
          },
        },
        // navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }
      },
    }
  },
	mounted() {
    console.log(['mounted', process.env.VUE_APP_API_URL]);
    this.user = localStorage.getItem('user-info');
    this.nowTime = moment(new Date()).format('Y.MM.D HH:mm:ss');
    let IMG_URL = 'https://eventapi.withsolve.com';

    if (process.env.NODE_ENV === 'development') {
      IMG_URL = 'http://localhost:3004';
    }

    this.imageSrc = IMG_URL;

    this.getMain();

    // eslint-disable-next-line no-unused-vars
		const swiper_01 = new Swiper(".swiper_01", {
			loop: true,
			autoplay: {
        delay: 4000,
				disableOnInteraction: false,
      },
			navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
		});

		new Swiper(".swiper_02", {
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
      },
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			on: {
				init: function () {
				document.querySelector(".top .right").prepend(document.querySelector(".swiper-pagination"));
				},
			},
		});

		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		const sliders = document.querySelectorAll('.scroll_area');
		function preventClick(e){
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		let isDown = false;
		let isDragged = false;
		let startX;
		let scrollLeft;

		sliders.forEach(function(slider){
			slider.addEventListener("mousedown", function(e){
				isDown = true;
				slider.classList.add("active");
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mouseleave", function(){
				isDown = false;
				slider.classList.remove("active");
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mouseup", function(){
				isDown = false;
				const elements = slider.querySelectorAll("a");
				if(isDragged){
				for(let i = 0; i<elements.length; i++){
					elements[i].addEventListener("click", preventClick);
				}
				}
				else{
				for(let i = 0; i<elements.length; i++){
					elements[i].removeEventListener("click", preventClick);
				}
				}
				slider.classList.remove("active");
				isDragged =  false;
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mousemove", function(e) {
				if (!isDown) return;
				isDragged =  true;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});
		});
	},
  methods: {
    getMain() {
      getEthPrice().then( res => {
        // console.log(['res', res]);
        if (res && res.data) {
          console.log(['getEthPrice', res.data[0], res.data[0].trade_price]);
          this.eth_price = res.data[0].trade_price;
        }
      });

      // main list
      const param = {
        site_code: 'blockchain',
        event_status: 'EVENT_STATUS01'
      }

      postData('/user/main', param).then( res => {
        console.log(['main', res, res.data.success]);
        if (res.data.success) {

          this.mission_list = res.data.event_list;
          this.upcoming_list = res.data.upcoming_list;
          this.onday_list = res.data.onday_list;
          this.topreward_list = res.data.topreward_list;
          this.ranking_list = res.data.ranking_list;
        }
      })

      console.log(['mission_list', this.mission_list.length]);
    },
    getETHPrice() {
      // api.getETHPrice().then( res => {
      //   // console.log(['res', res]);
      //   if (res && res.data) {
      //     // console.log(['data', res.data[0], res.data[0].trade_price]);
      //     this.eth_price = res.data[0].trade_price;
      //   }
      // });

    },
    goDetail(item) {
      console.log('goDetail', item);
      if (item.detail_image) {
        this.modalEvent = item;
        this.$refs['eventModal'].$refs["event-modal"].show();
      } else {
        showToast('안내페이지가 없습니다.');
      }
    },
    goStart(item) {
      console.log('goStart');
      this.$router.push('/event/'+item.event_code)
    },
    voteInterest(item) {
      console.log(['voteInterest', item]);
      //
      if (this.user) {
        const user = JSON.parse(this.user);

        const param = {
          event_code: item.event_code,
          user_code: user.user_code,
        };

        postData('/user/setInterest', param).then( res => {
          if (res.data.success) {
            this.getMain();
          }
        })
      }
    }
  }
}
</script>
