import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import middleware from './middleware/initialize'
import VueToastify from "vue-toastify";
import Toasted from 'vue-toasted';
import VueLogger from 'vuejs-logger';
import { BModal } from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/reset.css';
import './css/type.css';
import './css/layout.css';
import './css/page.css';
import './assets/scss/app.scss';
// import '~@/swiper/swiper-bundle.css';

Vue.config.productionTip = false;
Vue.component('b-modal', BModal);
Vue.use(VueToastify);
Vue.use(VueSocialSharing);
Vue.use(Toasted, {
  iconPack: 'fontawesome'
});
//* VueLogger 설정 *//
const loggerEnv = process.env.NODE_ENV;
const logLevel = loggerEnv === 'development' ? 'debug' : loggerEnv === 'info' ? 'debug' : loggerEnv === 'production' ? 'error' : 'info';

const logOptions = {
  isEnabled: true,
  logLevel : logLevel,
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, logOptions);
Vue.filter("makeComma", val =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

window.Kakao.init('fa1587171e3161bfbce3093055efe411')

new Vue({
  router,
  store,
  middleware,
  render: h => h(App),
}).$mount('#app')
