import Vue from "vue";
import axios from "axios";
const UPBIT_URL = 'https://api.upbit.com/v1/ticker?markets=KRW-ETH';

let API_URL = process.env.VUE_APP_API_URL;
const BBGO_URL = process.env.VUE_APP_BBGO_URL;

console.log(['API_URL', API_URL]);

if (process.env.NODE_ENV === 'development') {
  API_URL = '';
}

export async function postData(endPoint, params) {
  // console.log(['postData', params, API_URL]);
  const res = await axios.post(API_URL + endPoint, params);
  // console.log(['postData res', res]);
  return res;
}

export async function postBBGO(endPoint, params) {
  console.log(['postBBgo', BBGO_URL, endPoint, params]);
  const res = await axios.post(BBGO_URL + endPoint, params);
  console.log(['postBBGO res', res]);
  return res;
}

export async function getEthPrice() {
  return axios.get(UPBIT_URL);
}

export function showToast(msg, type, duration) {
  Vue.toasted.show(msg, {
    theme: "bubble",
    position: "top-center",
    type: type ? type : 'error',
    duration: duration ? duration : 2000
  });
}


export default {
  getETHPrice() {
    return axios.get(UPBIT_URL);
  },
  postEventList(param) {
    return axios.post(API_URL + '/v1/eventList', param);
  },
  getDigiPrice() {
    return axios.get('https://openapi.digifinex.com/v3/ticker?symbol=yct_usdt')
  },
  async txhash(param) {
    return await axios.post('http://test.bbgo.io:8081/txhash', param);
  },
  async transaction(param) {
    return await axios.post('http://test.bbgo.io:8081/transaction', param);
  }
}
